<template>
  <div class="page">
    <v-container fluid>
      <avp-breadcrumbs></avp-breadcrumbs>
      <v-row>
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-card rounded="lg">
            <v-tabs v-model="tab" centered>
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#comprovacao-acao-de-capacitacao">
                <v-icon class="p-2">mdi-eye</v-icon>
                Visualizar Comprovação de Ação de Capacitação
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <!-- Tab Dados da Comprovação -->
              <v-tab-item value="comprovacao-acao-de-capacitacao">
                <v-card rounded="lg">
                  <v-container>
                    <v-row>
                      <v-col cols="6" class="d-block">
                        <v-text-field
                          label="Ano"
                          v-model="form.ano"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="d-block">
                        <v-text-field
                          label="Semestre"
                          v-model="form.semestre"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="d-block">
                        <v-text-field
                          label="Nome da Instituição"
                          :value="
                            form.instituicao.nomeDesOutro
                              ? form.instituicao.nomeDesOutro
                              : form.instituicao.nome
                          "
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="d-block">
                        <v-text-field
                          label="Tipo da Ação de Capacitação"
                          v-model="form.tipoAcaoCapacitacao.nome"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="d-block">
                        <v-text-field
                          label="Eixo Temático"
                          v-model="form.eixoTematico.nome"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="d-block">
                        <v-text-field
                          label="Trilha de Aprendizagem"
                          v-model="form.trilhaDeAprendizagem.nome"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4" class="d-block">
                        <v-text-field
                          label="Nome da ação de capacitação"
                          v-model="form.nomeAcaoCapacitacao"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" class="d-block">
                        <v-text-field
                          label="Discente ou Docente"
                          v-model="form.tipoDaCondicaoCapacitacao"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" class="d-block">
                        <v-text-field
                          label="Carga Horária"
                          v-model="form.cargaHoraria"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="d-block">
                        <v-text-field
                          label="Mês e Ano de Início"
                          v-model="form.mesAnoInicio"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="d-block">
                        <v-text-field
                          label="Mês e Ano de Conclusão"
                          v-model="form.mesAnoConclusao"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
          <v-row class="text-center">
            <v-col cols="12" class="d-block">
              <v-btn color="primary" outlined @click="exibirPreviewArquivo"
                >Ver arquivo</v-btn
              >
            </v-col>

            <avp-preview-file
              :previewUrl="previewUrl"
              :visible="visibleDialogPreview"
              :path="form.anexo.path"
              descricao="Baixar arquivo"
              @onClose="onCloseDialogPreview()"
            >
            </avp-preview-file>
          </v-row>
          <br>
          <div class="text-center">
            <v-btn color="primary" outlined @click="$back()">Voltar</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ValidarComprovacao } from '~/commons/classes';

export default {
  name: "VisualizarComprovacaoAcaoDeCapacitacaoView",
  data: () => ({
    menu: false,
    tab: "comprovacao-acao-de-capacitacao",
    form: ValidarComprovacao.Build(),
    visibleDialogPreview: false,
    previewUrl: undefined,
  }),
  methods: {    
    exibirPreviewArquivo() {
      this.showPageLoading();
      this.$realizarDownloadPorPath({
        params: {
          path: this.form.anexo.path,
          nomeDeUsuario: this.$user.username,
        },
        responseType: "blob",
      }).then(({ data }) => {
        this.previewUrl = URL.createObjectURL(data);
        this.visibleDialogPreview = true;
      });
      this.hidePageLoading();
    },
    onCloseDialogPreview() {
      this.visibleDialogPreview = false;
    },
  },
  mounted() {
    this.$obterDadosComprovacaoAcaoCapacitacao({
      params: {
        cpf: this.$user.username,
        id: this.$route.params.id,
        ano: this.$route.params.ano,
        semestre: this.$route.params.semestre,
      },
    }).then(({ data }) => {
      this.form = data.comprovacao;
    });
  },
};
</script>