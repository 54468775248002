var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('avp-breadcrumbs'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tabs-slider'), _c('v-tab', {
    attrs: {
      "href": "#comprovacao-acao-de-capacitacao"
    }
  }, [_c('v-icon', {
    staticClass: "p-2"
  }, [_vm._v("mdi-account")]), _vm._v(" Comprovação de Ação de Capacitação ")], 1)], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "comprovacao-acao-de-capacitacao"
    }
  }, [_c('v-form', {
    ref: "form",
    staticClass: "px-10",
    attrs: {
      "autocomplete": "off"
    },
    model: {
      value: _vm.form.valid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid", $$v);
      },
      expression: "form.valid"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Ano",
      "hint": "Informe o ano da comprovação",
      "readonly": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.ano,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ano", $$v);
      },
      expression: "form.ano"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Semestre",
      "hint": "Informe o semestre da comprovação",
      "outlined": "",
      "readonly": "",
      "dense": ""
    },
    model: {
      value: _vm.form.semestre,
      callback: function ($$v) {
        _vm.$set(_vm.form, "semestre", $$v);
      },
      expression: "form.semestre"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": _vm.form.instituicaoId == 19 ? '3' : '6'
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Nome da Instituição",
      "hint": "Informe o nome da instituição",
      "items": _vm.lists.instituicoes,
      "item-value": 'id',
      "item-text": 'descricao',
      "rules": [function (v) {
        return _vm.$required(v);
      }],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.instituicaoId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "instituicaoId", $$v);
      },
      expression: "form.instituicaoId"
    }
  })], 1), _vm.form.instituicaoId == 19 ? _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Qual Instituição?",
      "hint": "Informe qual a instituição",
      "rules": [function (v) {
        return _vm.$required(v);
      }],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.nomeDeOutraInstituicao,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nomeDeOutraInstituicao", $$v);
      },
      expression: "form.nomeDeOutraInstituicao"
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Tipo da Ação de Capacitação",
      "hint": "Informe o tipo da ação de capacitação",
      "items": _vm.lists.tiposCapacitacao,
      "item-value": 'id',
      "item-text": 'descricao',
      "rules": [function (v) {
        return _vm.$required(v);
      }],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.tipoCapacitacaoId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tipoCapacitacaoId", $$v);
      },
      expression: "form.tipoCapacitacaoId"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": _vm.lists.trilhasAprendizagem.length ? '6' : '12'
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Eixo Temático",
      "hint": "Informe o eixo temático da ação de capacitação",
      "items": _vm.lists.eixosTematicos,
      "item-value": 'id',
      "item-text": 'descricao',
      "rules": [function (v) {
        return _vm.$required(v);
      }],
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function ($event) {
        return _vm.setTrilhasAprendizagem(_vm.form.eixoTematicoId);
      }
    },
    model: {
      value: _vm.form.eixoTematicoId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "eixoTematicoId", $$v);
      },
      expression: "form.eixoTematicoId"
    }
  })], 1), _vm.lists.trilhasAprendizagem.length ? _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Trilha de Aprendizagem",
      "hint": "Informe a trilha de aprendizagem",
      "items": _vm.lists.trilhasAprendizagem,
      "item-value": 'eixoTrilhaId',
      "item-text": 'descricao',
      "rules": [function (v) {
        return _vm.$required(v);
      }],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.trilhaDeAprendizagemId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "trilhaDeAprendizagemId", $$v);
      },
      expression: "form.trilhaDeAprendizagemId"
    }
  })], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Nome da ação de capacitação",
      "hint": "Informe o nome da ação de capacitação",
      "counter": "40",
      "rules": [function (v) {
        return _vm.$min(v, 5);
      }, function (v) {
        return _vm.$max(v, 40);
      }, function (v) {
        return _vm.$onlyTextAndNumbers(v);
      }],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.nomeDaAcaoDeCapacitacao,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nomeDaAcaoDeCapacitacao", $$v);
      },
      expression: "form.nomeDaAcaoDeCapacitacao"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Discente ou Docente",
      "hint": "Informe se a ação é discente ou docente",
      "items": _vm.$tipoCondicaoOptions,
      "item-text": 'descricao',
      "item-value": 'value',
      "rules": [function (v) {
        return _vm.$required(v);
      }],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.tipoDaCondicaoDeCapacitacao,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tipoDaCondicaoDeCapacitacao", $$v);
      },
      expression: "form.tipoDaCondicaoDeCapacitacao"
    }
  })], 1), _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###',
      expression: "'###'"
    }],
    attrs: {
      "label": "Carga Horária",
      "hint": "Informe a carga horária",
      "rules": [function (v) {
        return _vm.$required(v);
      }, function (v) {
        return _vm.$minValue(v, 1);
      }],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.form.cargaHoraria,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cargaHoraria", $$v);
      },
      expression: "form.cargaHoraria"
    }
  })], 1)], 1), _c('v-row', [_c('avp-date-picker', {
    ref: "dataInicioCapacitacao",
    attrs: {
      "label": "Dia, Mês e Ano de Início",
      "cols": "6",
      "required": "",
      "dataEdicao": _vm.form.dataInicioCapacitacao
    }
  }), _c('avp-date-picker', {
    ref: "dataFimCapacitacao",
    attrs: {
      "label": "Dia, Mês e Ano de Conclusão",
      "cols": "6",
      "required": "",
      "dataEdicao": _vm.form.dataFimCapacitacao
    }
  })], 1), _c('avp-upload-file', {
    attrs: {
      "anexo": _vm.form.anexo,
      "size": 8,
      "offset": 2,
      "showInfo": ""
    },
    model: {
      value: _vm.form.anexo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "anexo", $$v);
      },
      expression: "form.anexo"
    }
  }), _c('v-row', [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "offset": "2",
      "cols": "8"
    }
  }, [_c('v-checkbox', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('div', [_vm._v(" Declaro que permaneço atendendo aos critérios de elegibilidade do programa de Adicional de Valorização Profissional, conforme legislação em vigor. ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.atendeCriterioElegibilidade,
      callback: function ($$v) {
        _vm.$set(_vm.form, "atendeCriterioElegibilidade", $$v);
      },
      expression: "form.atendeCriterioElegibilidade"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('avp-buttons', {
    attrs: {
      "cancelText": 'Voltar'
    },
    on: {
      "click": function ($event) {
        return _vm.cadastrarComprovacao();
      },
      "cancel": function ($event) {
        return _vm.$back();
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }